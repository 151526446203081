import request from '@/utils/request'

// APP版本升级 分页
export function pageappVersion (query) {
  return request({
    url: '/biz/app-version/page',
    method: 'get',
    params: query
  })
}

// APP版本升级 详情
export function getappVersion (query) {
  return request({
    url: '/biz/app-version/detail',
    method: 'get',
    params: query
  })
}

// APP版本升级 新增
export function addappVersion (data) {
  return request({
    url: '/biz/app-version/add',
    method: 'post',
    data
  })
}

// APP版本升级 删除
export function delappVersion (data) {
  return request({
    url: '/biz/app-version/delete',
    method: 'post',
    data
  })
}

// 确认升级
export function confirmUpdate(data) {
  return request({
    url: '/biz/app-version/status',
    method: 'post',
    data
  })
}
