<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model
      ref="form"
      :model="dataForm"
      :rules="rules"
    >
      <a-form-model-item label="类型" prop="appType">
        <a-select v-model="dataForm.appType" placeholder="请选择">
          <a-select-option v-for="item in typeOptions" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
        </a-select>
        <!-- <a-radio-group button-style="solid" v-model="dataForm.appType">
          <a-radio-button v-for="item in typeOptions" :key="item.value" :value="item.value">{{ item.name }}</a-radio-button>
        </a-radio-group> -->
      </a-form-model-item>
      <a-form-model-item label="更新说明" prop="upNotes">
        <a-input v-model="dataForm.upNotes" placeholder="请输入" type="textarea"></a-input>
      </a-form-model-item>
      <a-form-model-item label="文件" prop="downloadUrl">
        <a-upload
          action="http://116.62.184.92:7091/admin-api/common/upload"
          :headers="header"
          class="upload-demo"
          ref="upload"
          @change="handleChange"
          :accept="'.bin'"
          :showUploadList="false"
        >
          <a-button :loading="uploadLoading"> <a-icon type="upload" /> 选取文件 </a-button>
          <span v-if="fileName">{{ fileName }}</span>
          <span v-show="false">{{ dataForm.downloadUrl }}</span>
        </a-upload>
      </a-form-model-item>
      <!-- <a-form-model-item label="文件" prop="downloadUrl">
        <a-upload
          action="http://116.62.184.92:7091/admin-api/common/upload"
          :headers="header"
          class="upload-demo"
          ref="upload"
          @change="handleChange"
          :accept="dataForm.appType===0?'.APK':(dataForm.appType===2?'.bin':'.ipa') "
          :showUploadList="false"
        >
          <a-button :loading="uploadLoading"> <a-icon type="upload" /> 选取文件 </a-button>
          <span v-if="fileName">{{ fileName }}</span>
          <span v-show="false">{{ dataForm.downloadUrl }}</span>
        </a-upload>
      </a-form-model-item> -->
      <a-form-model-item :label="'固件版本号名称'" prop="versionName">
        <a-input
          v-model="dataForm.versionName"
          placeholder="请输入"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item
        :label="'固件版本号'"
        prop="versionCode"
      >
        <a-input
          v-model="dataForm.versionCode"
          placeholder="请输入"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="更新设备类型" prop="sfAssign">
        <a-radio-group v-model="dataForm.sfAssign" button-style="solid">
          <a-radio-button :value="0">
            全部
          </a-radio-button>
          <a-radio-button :value="1">
            指定设备
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="dataForm.sfAssign == 1" label="设备" prop="list">
        <a-select mode="multiple" v-model="dataForm.list" placeholder="请选择" :filter-option="filterOption" @change="handleChange">
          <a-select-option v-for="item in deviceList" :key="item.id" :value="item.id">
            {{ item.typeName }}-{{ item.devCode }}{{ item.devName?`(${item.devName})`:'' }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>

  </a-drawer>
</template>

<script>
import store from '@/store'
import { addappVersion } from '@/api/system/version'
import { upload } from '@/api/pages/upload'
import { devicePage } from '@/api/pages/device'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      loading: false,
      header: { Authorization: 'Bearer ' + this.$store.getters.token },
      submitLoading: false,
      formTitle: '',
      fileName: '',
      uploadLoading: false,
      typeOptions: [
        { value: 0, name: '电信4G有磁有阀' },
        { value: 1, name: '电信4G有磁无阀' },
        { value: 2, name: '电信4G无磁有阀' },
        { value: 3, name: '电信4G无磁无阀' },
        { value: 4, name: '平台4G有磁有阀' },
        { value: 5, name: '平台4G有磁无阀' },
        { value: 6, name: '平台4G无磁有阀' },
        { value: 7, name: '平台4G无磁无阀' },
        { value: 8, name: '电信NB有磁有阀' },
        { value: 9, name: '电信NB有磁无阀' },
        { value: 10, name: '电信NB无磁有阀' },
        { value: 11, name: '电信NB无磁无阀' },
        { value: 12, name: '自己平台NB有磁有阀' },
        { value: 13, name: '自己平台NB有磁无阀' },
        { value: 14, name: '自己平台NB无磁有阀' },
        { value: 15, name: '自己平台NB无磁无阀' }
      ],
      // 表单参数
      dataForm: {
        appType: 2,
        compulsoryUp: 0,
        downloadUrl: '',
        id: 0,
        planUpTime: 0,
        planUpType: 0,
        remark: '',
        upHeSize: 0,
        upNotes: '',
        upType: 0,
        upUrl: '',
        upUrlType: 0,
        versionCode: '',
        versionName: '',
        sfAssign: 0,
        list: []
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        appType: [
          { required: true, message: '类型不能为空', trigger: 'blur' }
        ],
        downloadUrl: [
          { required: true, message: '请上传文件', trigger: 'blur' }
        ],
        upNotes: [
          { required: true, message: '更新说明不能为空', trigger: 'blur' }
        ],
        versionName: [
          { required: true, message: '版本号名称不能为空', trigger: 'blur' }
        ],
        versionCode: [
          { required: true, message: '版本号不能为空', trigger: 'blur' }
        ],
        sfAssign: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        list: [
          { required: true, message: '请选择', trigger: 'blur' }
        ]
      },
      deviceList: []
    }
  },
  filters: {
  },
  created () {
    console.log(upload)
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onClose () {
      this.open = false
    },
    // 上传成功
    handleChange (info) {
      console.log(info.file.status, info.file)
      if (info.file.status !== 'uploading') {
        this.uploadLoading = true
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        console.log(info.file.status, info.file.response.data.url, info.file)
        this.fileName = info.file.name
        this.dataForm.downloadUrl = info.file.response.data.url
        this.$message.success(`${info.file.name} 上传成功`)
        this.uploadLoading = false
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
        this.uploadLoading = false
      }
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.fileName = ''
      this.dataForm = {
        appType: 2,
        compulsoryUp: 0,
        downloadUrl: '',
        id: 0,
        planUpTime: 0,
        planUpType: 0,
        remark: '',
        upHeSize: 0,
        upNotes: '',
        upType: 0,
        upUrl: '',
        upUrlType: 0,
        versionCode: '',
        versionName: '',
        list: [],
        sfAssign: 0
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.loading = true
      devicePage({ pageNum: 1, pageSize: 2 ** 31 - 1 }).then(res => {
        console.log(res)
        this.deviceList = res.data.records
      }).finally(() => {
        this.loading = false
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          addappVersion(this.dataForm).then(response => {
            this.$message.success(
              '新增成功',
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
